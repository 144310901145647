import { isNotBlank, isString, isUndefined } from '@iheartradio/web.utilities';

import type { RecentlyPlayedResults } from '~app/api/types';

/**
 * Gets the current time as a floating point number
 *
 * @example
 * ```ts
 * const certainDate = new Date(1684945194000);
 * const time = getTimeFromDate(certainDate); // 11.19 in DST
 * ```
 *
 * @param date Date object
 * @returns returns the time part in 24h format
 */
export const getTimeFromDate = (date: Date): number => {
  return Number.parseFloat(`${date?.getHours()}.${date?.getMinutes()}`);
};

/**
 * Checks if url is relative
 *
 * @example
 * ```ts
 * isRelativeUrl('/browse/live/1469'); // true
 * isRelativeUrl('https://z100.iheart.com'); // false
 * isRelativeUrl(someNullOrUndefinedVar); // false
 * ```
 * @param url URL to check
 * @returns `true` if url is relative, `false` if url is absolute
 */
export const isRelativeUrl = (url: string): boolean => {
  if (!isString(url)) return false;

  const isAbsolute =
    url.indexOf('http://') === 0 || url.indexOf('https://') === 0;

  return !isAbsolute;
};

/**
 * Generates lead urls for Live Profile pages
 * @param liveProfileData Data from liveProfileResponse from WebAPI
 */
export const leadUrlForLiveProfile = (liveProfileData: {
  url: string;
  stationHost?: string;
  hostName: string;
  showInApp?: boolean;
  embeddedNewsUrl?: string;
}): string => {
  const { url, stationHost, showInApp, embeddedNewsUrl, hostName } =
    liveProfileData;

  if (showInApp && isRelativeUrl(url)) {
    return `${embeddedNewsUrl}${url}`;
  }

  if (stationHost && isRelativeUrl(url)) {
    return `https://${stationHost}${url}`;
  }

  // as we haven't implemented the content/music-festival pages in listen yet
  if (
    (!isRelativeUrl(url) && url?.includes('www.iheart.com/content/')) ||
    url?.includes('www.iheart.com/music-festival/')
  ) {
    const parsed = new URL(url);
    return `https://www.iheart.com${parsed.pathname}`;
  }

  if (!isRelativeUrl(url) && url?.includes('www.iheart.com')) {
    const parsed = new URL(url);
    return `${hostName}${parsed.pathname}`;
  }

  if (isRelativeUrl(url)) {
    return `${hostName}${url}`;
  }

  return url;
};

/**
 * Generate a formatted release date
 * @param date Epoch milliseconds
 * @param withDay include the day
 * @returns the formatted release date
 */
export function getReleaseDate(date: number, withDay = false): string {
  const dteReleaseDate = date ? new Date(date) : undefined;
  const locale = getBrowserLanguage({ fullLocale: true }) ?? 'en-US';

  const releaseDate =
    (
      dteReleaseDate instanceof Date &&
      dteReleaseDate.toString() !== 'Invalid Date'
    ) ?
      `${dteReleaseDate.toLocaleDateString(locale, {
        month: 'long',
        ...(withDay && { day: 'numeric' }),
        year: 'numeric',
      })}`
    : '';

  return releaseDate;
}

/**
 * Formats a string for total number of songs
 * @param songs number of songs
 * @returns formatted string
 */
export function getTotalSongs(songs: number): string {
  return Number.isFinite(songs) ?
      `${songs} song${songs > 1 || songs === 0 ? 's' : ''}`
    : '';
}

/**
 * Gets duration in the format of MM:SS or HH:MM:SS
 *
 * @example
 * ```ts
 * getDuration(30); // '00:30'
 * getDuration(90); // '01:30'
 * getDuration(3_660); // '01:01:00'
 * ```
 *
 * @param duration duration in seconds
 */
export function getDuration(duration: number | undefined): string {
  if (isUndefined(duration)) return '';

  const date = new Date(duration * 1000);
  if (date instanceof Date && date.toString() !== 'Invalid Date') {
    return date.getUTCHours() < 1 ?
        `${date.getUTCMinutes().toString().padStart(2, '0')}:${date
          .getUTCSeconds()
          .toString()
          .padStart(2, '0')}`
      : `${date.getUTCHours().toString().padStart(2, '0')}:${date
          .getUTCMinutes()
          .toString()
          .padStart(2, '0')}:${date
          .getUTCSeconds()
          .toString()
          .padStart(2, '0')}`;
  } else {
    return '';
  }
}

/**
 * Gets duration in the format of "MM min SS sec" or "SS sec"
 * @param duration duration in seconds
 * @returns formatted duration
 */
export function getFormattedDuration(duration: number): string {
  const min = Math.floor(duration / 60);
  const sec = duration % 60;
  const minutes = min > 0 ? `${min} min` : '';
  const seconds = sec > 0 ? `${sec} sec` : '';
  return `${minutes} ${seconds}`.trim();
}

/**
 * Gets the episode duration in the format of "HH hr MM min", "MM min", "HH hr MM min left" or "MM min left"
 *
 * @example
 * ```ts
 * getEpisodeDuration(2700); // "45 min"
 * getEpisodeDuration(3700, 600); // "35 min left"
 * getEpisodeDuration(3900); // "1 hr 5 min"
 * getEpisodeDuration(3900, 1800); // "35 min left"
 * getEpisodeDuration(7800, 900); // "1 hr 5 min left"
 * ```
 *
 * @param duration duration in seconds
 * @param secondsPlayed number of seconds already played
 * @returns duration or duration remaining
 */
export function getEpisodeDuration(
  duration: number | undefined,
  secondsPlayed = 0,
) {
  let durLength = Number(duration);
  if (secondsPlayed && Number(secondsPlayed) >= durLength) {
    return 'played';
  } else if (secondsPlayed) {
    durLength = durLength - Number(secondsPlayed);
  }
  const hours = Math.floor(durLength / 3600);
  const minutes = Math.floor((durLength % 3600) / 60);
  const hoursText = hours > 0 ? `${hours} hr ` : '';
  const minutesText = minutes > 0 ? `${minutes} min` : '';
  let secondsText = '';
  if (!hoursText && !minutesText) {
    secondsText = durLength > 0 ? `${durLength} sec` : '';
  }
  if (secondsPlayed) {
    return `${hoursText}${minutesText}${secondsText} left`;
  }

  return `${hoursText}${minutesText}${secondsText}`.trim();
}

/**
 * Generates a canonical url
 * @param route The app route ("/browse/live")
 * @param base the base url ("https://web-listen.radioedit.ihrint.com")
 * @returns {URL} The url
 */
export function getCanonicalURLForRoute(route: string, base?: string): URL {
  return new URL(route, base ?? 'https://listen.iheart.com');
}

/**
 * getBrowserLanguage
 * @remarks Gets the language (or the full locale string) of the current browser
 * @example
 * ```ts
 * const locale = getBrowserLanguage({ fullLocale: true }); // 'en-US'
 * const lang = getBrowserLanguage(); // 'en'
 * ```
 * @param options If fullLocale = true, returns the full locale, not just the language
 * @returns {string | undefined}
 */
export function getBrowserLanguage(options?: {
  fullLocale?: boolean;
}): string | undefined {
  const fullLocale = options?.fullLocale ?? false;
  const locale = globalThis.window?.navigator?.language;
  if (isNotBlank(locale)) {
    return fullLocale ? locale : locale.split('-').at(0);
  }
}

export function isBrowser() {
  return typeof window !== 'undefined';
}

export function getRecentlyPlayedArtwork(
  hit: RecentlyPlayedResults[number],
): string {
  if ('imagePath' in hit && hit.imagePath) {
    return hit.imagePath;
  } else if (
    'content' in hit &&
    hit.content &&
    hit.content[0] &&
    'logo' in hit.content[0] &&
    hit.content[0].logo
  ) {
    return hit.content[0].logo;
  } else if (
    'content' in hit &&
    hit.content &&
    hit.content[0] &&
    'imagePath' in hit.content[0] &&
    hit.content[0].imagePath
  ) {
    return hit.content[0].imagePath;
  } else if (
    'urls' in hit &&
    hit.urls &&
    'image' in hit.urls &&
    hit.urls.image
  ) {
    return hit.urls.image;
  }
  return '/hero-default-square.jpg';
}
